body {
  background-color: #000;
  color: #fff;
  font-family: "Coiny", cursive;
  font-family: "Noto Serif Vithkuqi", serif;
}

.App {
  width: 100%;
  height: 100vh;
  position: relative;
}

.socials {
  position: absolute;
  right: 50px;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 14px;
}

a {
  color: #fff;
  text-decoration: none;
}

@media only screen and (max-width: 600px) {
  .socials {
    font-size: 14px;
    gap: 10px;
    text-align: right;
    flex-direction: column;
    right: 12px;
    bottom: 50px;
  }
}
